<template>
  <router-view></router-view>
</template>

<script>
import store from '@/store';

export default {
  name: 'Home',
  beforeRouteEnter(to, from, next) {
    getProjects(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getProjects(to, next);
  },
};

async function getProjects(to, next) {
  const currentPage = parseInt(to.query.page, 10) || 1;
  await store.dispatch('project/fetchProjects', { page: currentPage });
  document.title = `${store.state.user.user.tenant.name} - Timelapse México`;
  next();
}
</script>
